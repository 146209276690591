<template>
  <v-row align="center" justify="center" class="pa-4">
    <loading :active.sync="loading"></loading>
    <v-col>
      <h1 style="font-weight: 800; color: #577eba">แก้ไขวีดีโอ</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อวีดีโอ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="video_name"
              label="ชื่อวีดีโอ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียดวีดีโอ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="video_desc"
              label="รายละเอียดวีดีโอ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="bannerDescription"
              label="รายละเอียด"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ลิงค์วีดีโอ</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="keyVdo"
              label="ลิงค์วีดีโอ"
              solo
              dense
              :rules="rules.required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">สร้างโดย</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="create_by"
              label="สร้างโดย"
              solo
              dense
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="editable">
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">แก้ไขโดย</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              v-model="update_by"
              label="สร้างโดย"
              solo
              dense
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <v-btn @click="submit()" color="primary">บันทึก</v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      video_name: "",
      keyVdo: "",
      video_desc: "",
      create_by: "",
      update_by: "",
      editable: true,

      activeFlag: true,
      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],

      loading: false,
      file: [],
      id: "",
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  async created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userPoliceWeb"))
    );
    console.log("userdata", this.user.user);
    let Bannerdata10 = JSON.parse(
      Decode.decode(localStorage.getItem("videodata"))
    );
    this.id = Bannerdata10.id;

    this.video_name = Bannerdata10.video_name;
    this.keyVdo = Bannerdata10.keyVdo;
    this.video_desc = Bannerdata10.video_desc;
    this.pic1URL = Bannerdata10.announce_pdf;
    this.create_by = Bannerdata10.create_by;

    // this.create_by = this.user.user.firstname + " " + this.user.user.lastname;
    this.update_by = this.user.user.firstname + " " + this.user.user.lastname;
  },
  methods: {
    // async showImg(val) {
    //   this.loading = true;
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/` + val
    //   );
    //   console.log("getAllproducts", response.data.data);
    //   this.img = response.data.data.imgUrl;
    //   this.loading = false;
    // },
    async dropdown() {
      this.loading = true;
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
      this.loading = false;
    },
    async getAllProduct() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
          this.brand +
          "&typeId=" +
          this.type +
          "&categoryId=" +
          this.category
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },
    async submitfileMain() {
      this.loading = true;
      let formData = new FormData();
      formData.append("uploadFile", this.file);
      console.log("formData", formData);
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      // const formData = {
      //   uploadFile: this.file,
      // };
      this.uploaded = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/files/formData`,
        formData
      );
      // .then(function () {
      console.log("SUCCESS!!");
      console.log("response", response);
      this.uploaded = false;
      if (response.data.response_status == "SUCCESS") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "อัพโหลดสำเร็จ",
        });
        this.pic1URL = response.data.data.path;
        this.loading = false;
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "อัพโหลดไฟล์ไม่สำเร็จ",
        });
        this.loading = false;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageBanners_10");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        // await this.submitfileMain();
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("userPoliceWeb"))
        );
        // const auth = {
        //   headers: { Authorization: `Bearer ${this.user.token}` },
        // };
        const data = {
          keyVdo: this.keyVdo,
          video_name: this.video_name,
          video_desc: this.video_desc,
          create_by: this.create_by,
          update_by: this.update_by,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/video/`+this.id,
          data
          // auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขวีดีโอสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageBanners_10");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>